import clsx from 'clsx'
import * as React from 'react'
import { useState } from 'react'
import styles from './viewing-env.module.scss'
import readMoreIcon from '@/images/icon-more.svg'

// メニューの開閉まわりはこのコンポーンネントが参考になる
const ViewingEnv: React.FC = () => {
  const [isExpanded, setExpanded] = useState(false)

  return (
    <div className={clsx(styles.viewingEnv, isExpanded ? styles.expanded : '')}>
      <div className={styles.toggle} onClick={() => setExpanded(!isExpanded)}>
        <p>
          「cognition cafe」は 以下の環境で
          <br className="u-pc-hidden" />
          ご覧いただくことを推奨しております。
        </p>
        <img
          src={readMoreIcon}
          className={clsx(
            styles.expandedArrow,
            isExpanded ? styles.rotateArrow : ''
          )}
        />
      </div>
      <br />
      <p>
        より安全で快適にご利用いただくためにOS
        やブラウザは最新のバージョンでご覧いただくことを推奨いたします。
      </p>
      <br />
      <strong>PC でのブラウザ視聴</strong>
      <br />
      Microsoft Edge 最新版
      <br />
      Google Chrome 最新版
      <br />
      Safari 最新版
      <br />
      <br />
      <strong>スマートフォン・タブレットでのブラウザ視聴</strong>
      <br />
      Google Chrome 最新版
      <br />
      Safari 最新版
      <br />
      <br />
      <strong>動画が再生されない場合</strong>
      <p>
        推奨環境において再生できない場合、複数の原因が考えられます。お手数ですが、以下をご確認ください。
      </p>
      <br />
      <p>
        【１】ブラウザ以外のアプリケーションを停止する、他のウインドウやタブを閉じる
      </p>
      <p>
        複数のアプリケーションが動作し、多くのウインドウやタブを開いていると、メモリやCPU
        に負荷がかかり再生に影響する場合があります。
      </p>
      <br />
      <p>【２】ブラウザキャッシュを削除する</p>
      <p>
        ブラウザに記憶されるキャッシュ（一時ファイル）に、再生に必要なデータが不完全に保存され、動画の再生に不具合が生じる場合があります。ブラウザのキャッシュ（一時ファイル）を削除してください。削除方法はご利用ブラウザ
        のヘルプをご覧ください。
      </p>
      <br />
      <p>【３】ブラウザのプラグインや拡張機能を無効にする</p>
      <p>
        ブラウザに拡張機能がインストールされている場合、動画再生に不具合が生じる場合があります。（例：広告を強制的に非表示にする拡張機能など）一度プラグインや拡張機能を全て無効にして、ブラウザを再起動してください。
      </p>
      <br />
      <p>【４】通信速度を確認する</p>
      <p>
        安定した動画視聴には最低でも500kbps以上の回線速度が必要です。快適な視聴には3Mbps以上の環境を推奨します。
      </p>
      <br />
      <p>【５】セキュリティ設定を確認する</p>
      <p>
        セキュリティーソフトやネットワーク機器、ご利用のプロバイダの設定によっては、高負荷の動画再生を制限するため、特定サイトの動画配信を制限している場合があります。設定の詳細はご利用ソフトやプロバイダのマニュアルをご確認ください。
      </p>
      <br />
      <p>【６】別のブラウザを導入する</p>
      <p>
        ブラウザのバージョンや設定により不具合がある場合があります。他ブラウザの利用もお試しください。
        <br />
        Microsoft Edge 最新版
        <br />
        Google Chrome 最新版
        <br />
        Safari 最新版
      </p>
    </div>
  )
}

export default ViewingEnv
