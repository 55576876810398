import { Link } from 'gatsby'
import * as React from 'react'
import styles from './navigation.module.scss'

type NavigationProps = {
  next?: string
  prev?: string
}

export const Navigation: React.FC<NavigationProps> = ({ next, prev }) => {
  return (
    <div className={styles.linkList}>
      {prev && <Link to={`/video/${prev}`}>前の動画</Link>}
      <Link to={'/video'}>一覧に戻る</Link>
      {next && <Link to={`/video/${next}`}>次の動画</Link>}
    </div>
  )
}
