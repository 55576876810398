import ReactPlayerLoader from '@brightcove/react-player-loader'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import styles from './video.module.scss'

import Seo from '@/components/general/seo'
import Layout from '@/components/layout/layout'

import { Navigation } from '@/components/video/navigation'
import ViewingEnv from '@/components/video/viewing-env'
import { ACCOUNT_ID } from '@/lib/constants'
import { BreadcrumbContext } from '@/types/breadcrumb'

const Page: React.FC<BreadcrumbContext> = ({ pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <Layout>
      <Seo title="統合失調症における認知障害" />
      <Helmet>
        {/* <!-- Ptengine Tag --> */}
        <script src="https://js.ptengine.jp/67a5keif.js"></script>
        {/* End Ptengine Tag */}
      </Helmet>
      <div className={styles.wrap}>
        <h1 className={styles.title}>第5回 統合失調症における認知障害</h1>
        <ReactPlayerLoader
          attrs={{ className: styles.video }}
          accountId={ACCOUNT_ID}
          videoId="6312731365112"
          playerId="hM1SBfBel"
          embedOptions={{ responsive: 'aspectRatio' }}
        />
        <div className={styles.videoBody}>
          <div className={styles.speaker}>
            <p>
              <span>ファシリテーター</span>
              <span>Lakshmi N. Yatham 教授(ブリティッシュコロンビア大学)</span>
            </p>
            <p>
              <span>エキスパート</span>
              <span>Philip D. Harvey教授 (マイアミ大学)</span>
            </p>
          </div>
          <Navigation prev="4" next="6" />
          <div className={styles.paragraph}>
            <p>
              統合失調症で顕著に認められる認知機能の低下について、その特徴や認知機能の評価に影響を与える統合失調症の症状、そして認知機能低下を改善し治療予後を向上させる治療戦略を考えます。
            </p>
            <p>大日本住友製薬（現：住友ファーマ株式会社）</p>
          </div>
          <ViewingEnv />
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={'統合失調症における認知障害'}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Page
